import React, { useEffect } from "react";

import "./App.css";
import { Route, Routes } from "react-router-dom";

import Home from "./components/Home/Home";
import DiscoverMore from "./components/pages/DiscoverMore/DiscoverMore";
import English from "./components/pages/English";
import FutureOfIt from "./components/pages/FutureOfIT/index";
import ComingSoon from "./components/common/ComingSoon";
import Science from "./components/pages/Science/index";
import Math from "./components/pages/Math/index";
import ExcutiveDirectorsPage from "./components/pages/HomePage/ExcutiveDirectorsPage";
import TusharPage from "./components/pages/HomePage/TusharPage";
import AboutPage from "./components/pages/AboutPage/index";
import LeaderShip from "./components/pages/LeaderShip/index";
import OurPrograms from "./components/pages/Programs/index";
import Layout from "./components/pages/Layout/index";
import CourseEnrollment from "./components/pages/Programs/CourseEnrollment";
import NarayananPage from "./components/pages/HomePage/NarayananPage";
import GinoPage from "./components/pages/HomePage/GinoPage";
import AdminLayout from "./components/AdminDashboard/pages/Layout/index";
import FacultyDetails from "./components/AdminDashboard/pages/AddFaculty/FacultyDeatails";
import StudentsData from "./components/AdminDashboard/pages/studentsData/StudentsData";
import { useSelector } from "react-redux";
import Login from "./components/authentication/Login/Login";
import LoginForm from "./components/authentication/Login/LoginForm";
import Register from "./components/authentication/SignUp";
import Dashboard from "./components/AdminDashboard/pages/Dashboard";
import LakshmiPage from "./components/pages/HomePage/LakshmiPage";
import NotFound from "./components/pages/NotFound";

function App() {
  const userType = useSelector((state) => state.loginReducer.userType);


  return (
    <>
      {userType === "Admin" ? (
        <AdminLayout>
          <Routes>
            <Route path="/facultyDetails" element={<FacultyDetails />} />
            <Route path="/facultyDetails/:id" element={<FacultyDetails />} />
            <Route path="/students" element={<StudentsData />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </AdminLayout>
      ) : (
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/loginform" element={<LoginForm />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/certaintyAI" element={<DiscoverMore />} />
            <Route path="/3rd to 12th Standard Courses" element={<English />} />
            <Route path="/futureofit" element={<FutureOfIt />} />
            <Route path="/comingsoon" element={<ComingSoon />} />
            <Route path="/science" element={<Science />} />
            <Route path="/math" element={<Math />} />
            {/* <Route path="/leadership" element={<ExcutiveDirectors />} /> */}
            {/* <Route path="/krishna" element={<ExcutiveDirectorsPage />} /> */}
            <Route path="/tushaar" element={<TusharPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/leadership" element={<LeaderShip />}>
              <Route path="krishna" element={<ExcutiveDirectorsPage />} />
              <Route path="narayanan" element={<NarayananPage />} />
              <Route path="tushaar" element={<TusharPage />} />
              <Route path="gino" element={<GinoPage />} />
              <Route path="lakshmiMarthala" element={<LakshmiPage />} />
            </Route>
            <Route path="/programs" element={<OurPrograms />} />
            <Route
              path="/programs/:programName"
              element={<CourseEnrollment />}
            />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </Layout>
      )}
    </>
  );
}

export default App;
